



export const testa = [
    {
        id: 1,
        title: 'MAXIMIZE YOUR WEB3 JOURNEY',
        desc: 'Use all the blockchain tools on a single platform',
    
    },
    {
        id: 2,
        title: 'MAXIMIZE YOUR WEB3 JOURNEY',
        desc: 'Use all the blockchain tools on a single platform',
    
    },

    
    

]

export default testa;