

import img1 from '../images/roadmap/rm-4.jpg'
import img2 from '../images/roadmap/rm-5.jpg'
import img3 from '../images/roadmap/rm-6.jpg'

const dataRoadmap = [
    {
        id: 1,
        title: 'PHASE 1',
        img1: img1,
        img2: img2,
        img3: img3,

        list : [
            {
                id: 1,
                text: 'Brand Identity',
            },
            {
                id: 2,
                text: 'Platform Launch',
            },
            {
                id: 3,
                text: 'Tools Releasing',
            },
            {
                id: 4,
                text: 'Tools for Holders',
            },
        ]

    
    },
    {
        id: 2,
        title: 'PHASE 2',
        img1: img1,
        img2: img2,
        img3: img3,

        list : [
            {
                id: 1,
                text: 'SaaS',
            },
            {
                id: 2,
                text: 'DAO Tools Releasing',
            },
            {
                id: 3,
                text: 'Collection Mint',
            },
            {
                id: 4,
                text: 'Points Goveranance',
            },
        ]

    
    },
    {
        id: 3,
        title: 'PHASE 3',
        img1: img1,
        img2: img2,
        img3: img3,

        list : [
            {
                id: 1,
                text: 'Cross Chain Tools',
            },
            {
                id: 2,
                text: 'Ecosystem Adoption',
            },
            {
                id: 3,
                text: 'Platform Upgrade',
            },
            {
                id: 4,
                text: 'Products Development',
            },
        ]

    
    },
    {
        id: 4,
        title: 'PHASE 4',
        img1: img1,
        img2: img2,
        img3: img3,

        list : [
            {
                id: 1,
                text: 'Company Launch',
            },
            {
                id: 2,
                text: 'Green Platform Creation',
            },
            {
                id: 3,
                text: 'Green Ecosystem Development',
            },
            {
                id: 4,
                text: 'Holders Benefits',
            },
        ]

    
    }, 

]

export default dataRoadmap;