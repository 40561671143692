



import img1 from '../images/svg/work-1.svg'
import img2 from '../images/svg/work-2.svg'
import img3 from '../images/svg/work-3.svg'
import img4 from '../images/svg/work-4.svg'

const dataWork = [
    {
        id: 1,
        img: img1,
        title: 'LOGIN PLATFORM',
        text :'Log in to the platform, update your profile settings, connect your socials and wallets for an enhanced user experience.',
    },
    {
        id: 2,
        img: img2,
        title: 'DISCOVER TOOLS',
        text :'Explore the platform and discover all the available tools. Choose the ones that suit you best and use them to make decisions through statistical analysis or utilize them to burn NFTs, send multiple NFTs, and transfer Solana to multiple wallets.',
    },

    {
        id: 3,
        img: img3,
        title: 'MAXIMIZE YOUR PROFIT',
        text :'The tools can be used to maximize blockchain profits, gain information ahead of others, turn your NFTs into earnings, and transfer all the content from one wallet to another.',
    },

    {
        id: 4,
        img: img4,
        title: 'EARN POINTS',
        text :'The more you use the platform for your benefits, the more exclusive points you\'ll receive to spend on unique rewards available only on our platform.',
    },

]

export default dataWork;