const menus = [
    {
        id: 1,
        name: 'Home',
        links: '#',
        
    },
    {
        id: 2,
        sectionId: 'about',
        name: 'About',
        links: '#',
        
    },
    {
        id: 4,
        sectionId: 'roadmap',
        name: 'Road Map',
        links: '#',
       
    },
    {
        id: 5,
        sectionId: 'howitworks',
        name: 'How it works',
        links: '#',
       
    },
    {
        id: 6,
        sectionId: 'team',
        name: 'Team',
        links: '#',
       
    },
    {
        id: 7,
        sectionId: 'faq',
        name: 'Faq',
        links: '#',
       
    }
    //{
        //id: 5,
       // name: 'Pages',
       // links: '#',
       // namesub: [
      //      {
       //         id: 1,
       //         sub: 'Sign in ',
       //         links: '/signin'
       //     },
        //    {
        //        id: 2,
        //        sub: 'Sign up',
        //        links: '/signup'
         //   },
        //    {
         //       id: 3,
         //       sub: 'Faq v1',
         //       links: '/faq-v1'
         //   },
         //   {
         //       id: 4,
         //       sub: 'Faq v2',
         //       links: '/faq-v2'
         //   },
         //   {
         //       id: 5,
         //       sub: 'Our Team',
         //       links: '/our-team'
          //  },
          //  {
          //      id: 6,
        //        sub: 'Collection',
         //       links: '/collection'
         //   },
         //   {
         //       id: 7,
         //       sub: 'Testimonial',
         //       links: '/testimonial'
         //   },
         //   {
         //       id: 8,
         //       sub: 'Item Details',
         //       links: '/item-details'
          //  },
            //{
          //      id: 9,
          //      sub: 'Comming Soon',
          //      links: '/comming-soon'
            //},
           // {
           //     id: 10,
           //     sub: 'Page 404',
           //     links: '/page-404'
           // },
       // ],
    //},
    
   //} {
    //}    id: 6,
    //}    name: 'Contact',
    //}    links: '/contact',
   //} },
    
]

export default menus;