



import img1 from '../images/team/team-1.jpg'
import img2 from '../images/team/team-2.jpg'
import img3 from '../images/team/team-3.jpg'
import img4 from '../images/team/team-4.jpg'
import img5 from '../images/team/team-5.jpg'
import img6 from '../images/team/team-6.jpg'
import img7 from '../images/team/team-7.jpg'


const dataTeam = [
    {
        id: 1,
        img: img1,
        name: 'WilloWonkaX',
        position :'CEO',
        social: [
            
        ]
    },
    {
        id: 2,
        img: img2,
        name: 'CicoBomb',
        position :'CTO',
        social: [
           
        ]
    },
    {
        id: 3,
        img: img3,
        name: '!Raisero',
        position :'Backend Developer',
        social: [
           
        ]
    },
    {
        id: 4,
        img: img4,
        name: 'BLOBBY',
        position :'Backend Developer',
        social: [
            
        ]
    },
    {
        id: 5,
        img: img5,
        name: 'KsKFabietto',
        position :'Frontend Developer',
        social: [
           
        ]
    },
    {
        id: 6,
        img: img6,
        name: 'Robert Fox',
        position :'UI/UX Designer',
        social: [
            
        ]
    },
    {
        id: 7,
        img: img7,
        name: 'Devon Lane',
        position :'Ux Architect',
        social: [
            {
                id: 1,
                icon: 'fab fa-linkedin'
            },
            {
                id: 2,
                icon: 'fab fa-facebook'
            },
            {
                id: 3,
                icon: 'fab fa-instagram'
            },
            {
                id: 4,
                icon: 'fab fa-telegram'
            },
        ]
    },

]

export default dataTeam;