



const dataAbout = [

    {
        id: 1,
        title : 'Multi Chain',
        text : 'We believe in the global use of blockchain, and that is why our tools will be developed to be used on different chains like Solana, Bitcoin, Ethereum, Matic, and many others.'
    },
    {
        id: 2,
        title : 'One Platform',
        text : 'One platform to manage them all. You won\'t need anything else, it\'s all here at your disposal.'
    },
    {
        id: 3,
        title : 'Free to use',
        text : 'Our tools are free forever. We understand that sometimes people need to use tools but can\'t spend a lot of money to do so. Our models provide free access to all and offer some premium tools for our holders.'
    },
    {
        id: 4,
        title : 'Safe',
        text : 'All the tools on our platform, from access to approvals, have undergone internal audits to ensure their security. Our smart contracts prioritize privacy and do not expose any sensitive data. You are safe! Relax and enjoy our services.'
    },
    

]

export default dataAbout;