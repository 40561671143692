


const dataFaqs = [
    {
        id: 1,
        title: 'What is MetaX?',
        text : ' It\'s a project focused on creating tools and applications for the web3 space, with a primary focus on the Solana blockchain. The overarching goal of MetaX is to make blockchain technology accessible to a wide audience by developing user-friendly and secure web applications. Additionally, you have plans to expand your services to include other blockchains like Bitcoin (BTC) and Ethereum (ETH) in the future.',
        show: 'show'
    
    },
    {
        id: 2,
        title: 'Do I need to pay or hold an NFT to use MetaX?',
        text : 'The basic tools of MetaX are available to all Solana users; simply connect your wallet and start using them right away. Some tools are reserved for holders of our collection. If you want to maximize your use of Solana tools, consider taking a look at our collection to maximize your profits.'
    },
    {
        id: 3,
        title: 'Is MetaX safe?',
        text : 'MetaX is safe; all our tools have been designed not to expose users\' sensitive data, making the use of the tools secure even for beginners. Our team continuously reviews and audits the code, ensuring high security standards.'
    },
    {
        id: 4,
        title: 'Where we can buy and sell MetaX NFts ?',
        text : 'Our collection is available on major Solana marketplaces, check our socials and join us.'
    },
    {
        id: 5,
        title: 'Who are the team behind the project?',
        text : 'The team is comprised of Italian developers. We\'ve decided to make public and available to everyone the tools found on various platforms that require substantial amounts of Solana to use'
    }
    

   
]

export default dataFaqs;