import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

Button03.propTypes = {
    title : PropTypes.string,
};

function Button03(props) {
    const {title , path} = props;
    return (
        <Link to="https://discord.gg/metaxtools" className="tf-button style-2 "><i className="icon-fl-vt"></i>{title}</Link>
    );
}

export default Button03;